import React, { useContext, useGlobal } from 'reactn';
import AuthenticatedLayout from './AuthenticatedLayout';
import UnauthenticatedLayout from './UnauthenticatedLayout';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import {
  toAssessmentsMarketing,
  toBrowseDentalAssistantJobs,
  toBrowseDentalFrontOfficeJobs,
  toBrowseDentalHygienistJobs,
  toBrowseDentalLabTechJobs,
  toBrowseDentalSalesRepJobs,
  toBrowseDentistJobs,
  toCandidateMatchingMarketing,
  toClassifiedsList,
  toCorporateContact,
  toDentalCareerGuide,
  toEmployerLearnMore,
  toEventsList,
  toInstructorToolkit,
  toJobSearchResults,
  toJobseekerToolkit,
  toResources,
  toSalarySurvey,
  toStaffTempMarketingPage,
  toWhoKit,
  toInviteToApplyMarketing,
  toSubscriptionProductMarketing,
  toBasicProductMarketing,
  toPremiumProductMarketing,
} from '../../routes';
import { StaticImage } from 'gatsby-plugin-image';
import { FaEnvelope, FaNewspaper, FaPuzzlePiece } from 'react-icons/fa';
import { UserContext } from '../../context/UserContext';
import { GlobalContext, GlobalContextType } from '../../context/GlobalContext';
import LoadingSpinner from '../common/LoadingSpinner';

export type NavCategoriesType = {
  title: string;
  mainNav: {
    header: string;
    description: string;
    links: { route: string; label: string }[];
    cta: {
      route: string;
      label: string;
    };
  };
  featureNav?: {
    header: string;
    links: {
      route: string;
      icon: ReactNode;
      header: string;
      description: string;
    }[];
    cta?: {
      route: string;
      label: string;
    };
  };
  blogNav?: boolean;
}[];

const Layout = (
  props: PropsWithChildren<{
    location: any;
    contentOnly?: boolean;
  }>
) => {
  const [isAuthenticated] = useGlobal('isAuthenticated');
  const context = useContext(UserContext);
  const { updateUrlParams } = useContext(GlobalContext) as GlobalContextType;
  const [displayLeadForm, setDisplayLeadForm] = useState(false);

  useEffect(() => {
    if (!!props?.location?.search) {
      const search = new URLSearchParams(props.location.search);
      updateUrlParams(search);
    }
    if (!document.getElementById('email')) {
      setDisplayLeadForm(true);
    } else {
      setDisplayLeadForm(false);
    }
  }, [props.location]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mainContent = document.getElementById('main-content');
      mainContent?.scrollTo(0, 0);
    }
  }, [props.location?.pathname]);

  const userLoaded = isAuthenticated && 'user' in context && !!context.user;

  const navCategories: NavCategoriesType = [
    {
      title: 'Jobseekers',
      mainNav: {
        header: 'Find a Job',
        description: 'Browse jobs for the following positions near you.',
        links: [
          {
            label: 'Dental Assistants',
            route: userLoaded
              ? toJobSearchResults('DA')
              : toBrowseDentalAssistantJobs,
          },
          {
            label: 'Dental Front Office',
            route: userLoaded
              ? toJobSearchResults('FO')
              : toBrowseDentalFrontOfficeJobs,
          },
          {
            label: 'Dental Hygienists',
            route: userLoaded
              ? toJobSearchResults('DH')
              : toBrowseDentalHygienistJobs,
          },
          {
            label: 'Dentists',
            route: userLoaded ? toJobSearchResults('DD') : toBrowseDentistJobs,
          },
          {
            label: 'Dental Lab Technicians',
            route: userLoaded
              ? toJobSearchResults('LT')
              : toBrowseDentalLabTechJobs,
          },
          {
            label: 'Dental Sales Rep',
            route: userLoaded
              ? toJobSearchResults('SR')
              : toBrowseDentalSalesRepJobs,
          },
        ],
        cta: {
          label: 'Browse all dental jobs',
          route: userLoaded ? toJobSearchResults('ANY') : '/dental-jobs/zones/',
        },
      },
      featureNav: {
        header: 'Dental Professional Features',
        links: [
          {
            icon: (
              <StaticImage
                loading="lazy"
                src="../../images/homepage/pie-chart.png"
                placeholder="blurred"
                layout="constrained"
                alt="pie chart"
                width={35}
                height={35}
                style={{ pointerEvents: 'none' }}
              />
            ),
            header: 'Assessments',
            description:
              'Understand yourself better so you can make the right career moves. Assessments are always FREE for job seekers.',
            route: toAssessmentsMarketing,
          },
          {
            icon: (
              <StaticImage
                loading="lazy"
                src="../../images/ce-icon.png"
                placeholder="blurred"
                layout="constrained"
                alt="open book"
                width={35}
                height={35}
                style={{ pointerEvents: 'none' }}
              />
            ),
            header: 'Continuing Education (CE)',
            description:
              'Track & maintain CE credits right on your DentalPost dashboard, and get special course pricing and offers.',
            route: toEventsList,
          },
          {
            icon: (
              <StaticImage
                loading="lazy"
                src="../../images/temp-icon.png"
                placeholder="blurred"
                layout="constrained"
                alt="searching for temp work"
                width={35}
                height={35}
                style={{ pointerEvents: 'none' }}
              />
            ),
            header: 'Temp Jobs',
            description:
              "Open to temp? Choose the days you're available to work and get alerts when practices need your help. Control your schedule, make extra money.",
            route: toStaffTempMarketingPage,
          },
        ],
      },
    },
    {
      title: 'Employers',
      mainNav: {
        header: 'Post a Job',
        description:
          'Compare product options to meet your search needs and budget.',
        links: [
          {
            label: 'Premium Job Posting Subscription',
            route: `${toSubscriptionProductMarketing}`,
          },
          {
            label: 'Basic Job Posting',
            route: `${toBasicProductMarketing}`,
          },
          {
            label: 'Premium Job Posting',
            route: `${toPremiumProductMarketing}`,
          },
          {
            label: 'Corporate Services',
            route: toCorporateContact,
          },
        ],
        cta: {
          label: 'View all products & pricing',
          route: toEmployerLearnMore,
        },
      },
      featureNav: {
        header: 'Dental Employer Features',
        links: [
          {
            icon: (
              <FaEnvelope
                className="text-secondary"
                style={{ fontSize: '35px' }}
              />
            ),
            header: 'Invite to Apply',
            description:
              'Proactively reach out to candidates and invite them to apply to your job posting',
            route: toInviteToApplyMarketing,
          },
          {
            icon: (
              <FaPuzzlePiece
                className="text-success"
                style={{ fontSize: '35px' }}
              />
            ),
            header: 'Candidate Matching',
            description:
              'Save time and money with DentalPost’s proprietary matching algorithm.',
            route: toCandidateMatchingMarketing,
          },
          {
            icon: (
              <FaNewspaper className="text-info" style={{ fontSize: '35px' }} />
            ),
            header: 'Dental Classifieds',
            description:
              'Our dental classified ads connect buyers and sellers locally and around the world.',
            route: toClassifiedsList,
          },
        ],
      },
    },
    {
      title: 'Resources',
      mainNav: {
        header: 'Resources',
        description:
          'Access the data, guidance and information you need to be the most successful in your dental career.',
        links: [
          {
            label: '2024 Salary Survey Report',
            route: toSalarySurvey,
          },
          {
            label: 'Industry Events',
            route: toEventsList,
          },
          {
            label: 'Jobseeker Toolkit',
            route: toJobseekerToolkit,
          },
          {
            label: 'Hiring Toolkit',
            route: toWhoKit,
          },
          {
            label: 'Dental Career Guide',
            route: toDentalCareerGuide,
          },
          {
            label: 'Educator Toolkit',
            route: toInstructorToolkit,
          },
        ],
        cta: {
          label: 'View all resources',
          route: toResources,
        },
      },
      blogNav: true,
    },
  ];

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: userLoaded || !isAuthenticated ? '100%' : 0,
          width: userLoaded || !isAuthenticated ? '100%' : 0,
          overflow: userLoaded || !isAuthenticated ? undefined : 'hidden',
          transition: 'height 1s linear',
          transform: 'scale(1)',
        }}
      >
        {userLoaded ? (
          <AuthenticatedLayout navCategories={navCategories}>
            {props.children}
          </AuthenticatedLayout>
        ) : (
          <>
            <UnauthenticatedLayout
              displayLeadForm={displayLeadForm}
              navCategories={navCategories}
            >
              {props.children}
            </UnauthenticatedLayout>
          </>
        )}
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: isAuthenticated && !userLoaded ? '100%' : 0,
          width: isAuthenticated && !userLoaded ? '100%' : 0,
          overflow: isAuthenticated && !userLoaded ? undefined : 'hidden',
        }}
      >
        <LoadingSpinner />
      </div>
    </>
  );
};

export default Layout;
