import React, { useState, useEffect, PropsWithChildren } from 'react';
import types, {
  JobDateType,
  ProductOrderViewType,
} from '../types/services-api';
import agent from '../agent';
import { navigate } from 'gatsby';
import { toJobPostingEdit } from '../routes';

export type JobPostingContextType = {
  jobPostingsList: types.JobOwnerSummaryViewType[];
  subscription: ProductOrderViewType | null | undefined;
  unassignedSlots: types.JobSlotType[];
  jobSlotList: types.JobSlotType[];
  refreshJobLists: (navigateToLatest?: boolean) => void;
  refreshSubscription: () => void;
  updateSubscription: (values: Partial<ProductOrderViewType>) => void;
  pendingDraft: PendingDraftType | undefined;
  createPendingDraft: (draft?: PendingDraftType) => void;
};

export const JobPostingContext = React.createContext<JobPostingContextType>({
  jobPostingsList: [],
  subscription: null,
  unassignedSlots: [],
  jobSlotList: [],
  refreshJobLists: () => {},
  refreshSubscription: () => {},
  updateSubscription: () => {},
  pendingDraft: undefined,
  createPendingDraft: (draft) => {},
});

export type ResumeSearchFiltersType = {
  keyword?: string;
  type?: string;
  evaluation?: string;
  photo?: boolean;
  disc?: boolean;
  values?: boolean;
  skills?: boolean;
  culture?: boolean;
  dow: string;
  radius: string;
  limit: number;
  offset: number;
};

export type PendingDraftType = {
  isSubscription: boolean;
  address: string;
  address2: string;
  applylink: string;
  atsId: string;
  city: string;
  contact: string;
  description: string;
  dow: string;
  email: string;
  isConfidential: boolean;
  isExpired: boolean;
  isPlanned: boolean;
  isUnslotted: boolean;
  jobDates: JobDateType[];
  lat: string;
  lng: string;
  name: string;
  phone: string;
  position: string;
  specialty: string;
  state: string;
  status: string;
  website: string;
  zip: string;
};

export interface ResumeSearchParametersType extends ResumeSearchFiltersType {
  position: string;
  zip: string;
}

export const DEFAULT_RESUME_SEARCH_FILTERS: ResumeSearchFiltersType = {
  dow: '0000000',
  radius: 'NONE',
  limit: 25,
  offset: 0,
};

const JobPostingContextProvider = (props: PropsWithChildren) => {
  const [jobPostingsList, setJobPostingsList] = useState<
    types.JobOwnerSummaryViewType[]
  >([]);
  const [jobSlotList, setJobSlotList] = useState<types.JobSlotType[]>([]);
  const [subscription, setSubscription] = useState<
    ProductOrderViewType | null | undefined
  >(undefined);
  const [unassignedSlots, setUnassignedSlots] = useState<types.JobSlotType[]>(
    []
  );
  const [pendingDraft, setPendingDraft] = useState<
    PendingDraftType | undefined
  >(undefined);

  useEffect(() => {
    fetchJobPostings();
    fetchJobSlots();
  }, []);

  function fetchJobPostings(navigateToLatest?: boolean) {
    agent.Employer.jobs()
      .then((res) => {
        setJobPostingsList(res.data);
        if (navigateToLatest) {
          const latestDraft = res.data.filter(
            (job) => job.status === 'DRAFT'
          )[0];
          navigate(toJobPostingEdit(latestDraft.id));
        }
      })
      .catch((err) => {
        // TODO: show a global, floating Alert?
        console.warn(err.message);
      });
  }

  function fetchJobSlots() {
    agent.Employer.jobSlots()
      .then((res) => {
        const response = res.data;
        const unassigned = response
          .filter((slot) => slot.activeJobId === 0)
          .sort((a, b) => (a.slotId > b.slotId ? 1 : -1));
        setJobSlotList(response);
        if (response.length > 0) {
          agent.Employer.subscription(response[0].orderId)
            .then((subRes) => {
              setSubscription(subRes.data);
            })
            .catch((err) => {
              console.log(err.message);
            });
        } else {
          setSubscription(null);
        }
        setUnassignedSlots(unassigned);
      })
      .catch((err) => {
        // TODO: show a global, floating Alert?
        console.warn(err.message);
      });
  }

  function refreshJobLists(navigateToLatest?: boolean) {
    fetchJobPostings(navigateToLatest);
  }

  function refreshSubscription() {
    fetchJobSlots();
  }

  function updateSubscription(values: Partial<ProductOrderViewType>) {
    if (!!subscription) {
      setSubscription({ ...subscription, ...values });
    }
  }

  function createPendingDraft(draft: PendingDraftType | undefined) {
    setPendingDraft(draft);
  }

  JobPostingContext.displayName = 'Job Posting Context';

  return (
    <JobPostingContext.Provider
      value={{
        jobPostingsList,
        unassignedSlots,
        jobSlotList,
        refreshJobLists,
        refreshSubscription,
        subscription,
        updateSubscription,
        pendingDraft,
        createPendingDraft,
      }}
    >
      {props.children}
    </JobPostingContext.Provider>
  );
};

export default JobPostingContextProvider;
