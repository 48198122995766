import React from 'react';
import {
  FaHome,
  FaSearch,
  FaAddressCard,
  FaChartPie,
  FaComments,
  FaStar,
  FaBook,
  FaNewspaper,
  FaShieldAlt,
  FaCog,
  FaCopy,
  FaFileAlt,
  FaGift,
  FaCalendarCheck,
  FaBuilding,
  FaClipboardList,
  FaEdit,
  FaPaperPlane,
  FaBell,
  FaMobileAlt,
  FaIdCard,
  FaDollarSign,
  FaCircle,
  FaMap,
  FaHeart,
  FaSmile,
  FaUsers,
  FaGem,
  FaTooth,
  FaCalendarAlt,
  FaBriefcase,
  FaChartBar,
  FaBeer,
} from 'react-icons/fa';

const IconHelper = (props) => {
  const { icon } = props;

  if (icon === 'FaHome') {
    return <FaHome {...props} />;
  } else if (icon === 'FaCalendarAlt') {
    return <FaCalendarAlt {...props} />;
  } else if (icon === 'FaSearch') {
    return <FaSearch {...props} />;
  } else if (icon === 'FaBuilding') {
    return <FaBuilding {...props} />;
  } else if (icon === 'FaAddressCard') {
    return <FaAddressCard {...props} />;
  } else if (icon === 'FaChartPie') {
    return <FaChartPie {...props} />;
  } else if (icon === 'FaComments') {
    return <FaComments {...props} />;
  } else if (icon === 'FaStar') {
    return <FaStar {...props} />;
  } else if (icon === 'FaBook') {
    return <FaBook {...props} />;
  } else if (icon === 'FaNewspaper') {
    return <FaNewspaper {...props} />;
  } else if (icon === 'FaShieldAlt') {
    return <FaShieldAlt {...props} />;
  } else if (icon === 'FaCog') {
    return <FaCog {...props} />;
  } else if (icon === 'FaFileAlt') {
    return <FaFileAlt {...props} />;
  } else if (icon === 'FaGift') {
    return <FaGift {...props} />;
  } else if (icon === 'FaClipboardList') {
    return <FaClipboardList {...props} />;
  } else if (icon === 'FaEdit') {
    return <FaEdit {...props} />;
  } else if (icon === 'FaPaperPlane') {
    return <FaPaperPlane {...props} />;
  } else if (icon === 'FaBell') {
    return <FaBell {...props} />;
  } else if (icon === 'FaMobileAlt') {
    return <FaMobileAlt {...props} />;
  } else if (icon === 'FaIdCard') {
    return <FaIdCard {...props} />;
  } else if (icon === 'FaDollarSign') {
    return <FaDollarSign {...props} />;
  } else if (icon === 'FaCircle') {
    return <FaCircle {...props} />;
  } else if (icon === 'FaMap') {
    return <FaMap {...props} />;
  } else if (icon === 'FaHeart') {
    return <FaHeart {...props} />;
  } else if (icon === 'FaSmile') {
    return <FaSmile {...props} />;
  } else if (icon === 'FaGem') {
    return <FaGem {...props} />;
  } else if (icon === 'FaTooth') {
    return <FaTooth {...props} />;
  } else if (icon === 'FaUsers') {
    return <FaUsers {...props} />;
  } else if (icon === 'FaBriefcase') {
    return <FaBriefcase {...props} />;
  } else if (icon === 'FaChartBar') {
    return <FaChartBar {...props} />;
  } else if (icon === 'FaCalendarAlt') {
    return <FaCalendarAlt {...props} />;
  } else if (icon === 'FaCopy') {
    return <FaCopy {...props} />;
  } else {
    return <FaBeer {...props} />;
  }
};

export default IconHelper;
