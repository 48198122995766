import React, { useState, useEffect, useGlobal } from 'reactn';
import { PropsWithChildren } from 'react';
import agent from '../agent';
import types from '../types/services-api';
import ProfileContext from './ProfileContext';
import JobSearchContext from './JobSearchContext';
import ConversationsContext from './ConversationsContext';
import InterviewContext from './InterviewContext';

export type UserType = types.UserEmployerViewType | types.StaffProfileViewType;

type SharedContextType = {
  defaultMapCenter: { lat: number; lng: number } | null;
  setDefaultMapCenter: React.Dispatch<
    React.SetStateAction<{ lat: number; lng: number }>
  >;
  updateUser: (updatedUserValues: {}) => void;
};
export interface StaffContextType extends SharedContextType {
  user: types.StaffProfileViewType;
}

export interface EmployerContextType extends SharedContextType {
  user: types.UserEmployerViewType;
  employerTechnologiesAssessment: {
    skill: string;
    score: number;
    category: string;
  }[];
}

export type UserContextType = StaffContextType | EmployerContextType;

export function isEmployerType(
  object: any
): object is types.UserEmployerViewType {
  return !!object && 'empType' in object;
}

export function isStaffType(object: any): object is types.StaffProfileViewType {
  return !!object && 'position' in object;
}

export const UserContext = React.createContext<UserContextType | {}>({});

const UserContextWrapper = (props: PropsWithChildren) => {
  const [isEmployer] = useGlobal('isEmployer');
  const [isStaff] = useGlobal('isStaff');
  const [user, setUser] = useState<UserType | null>(null);
  const [employerTechnologiesAssessment, setEmployerTechnologiesAssessment] =
    useState<{ skill: string; score: number; category: string }[]>([]);
  const [defaultMapCenter, setDefaultMapCenter] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  useEffect(() => {
    if (isEmployer) {
      fetchEmployerUser();
    } else if (isStaff) {
      fetchStaffUser();
    }
  }, []);

  function fetchEmployerUser() {
    agent.Employer.current()
      .then((res) => {
        setUser({
          ...res.data,
        });
        fetchEmployerTechnologies(res.data.technologies);
      })
      .catch((err) => {
        // TODO: show a global, floating Alert?
        console.error(err.message);
      });
  }

  function fetchEmployerTechnologies(skills: string[]) {
    agent.Lists.getEmployerSkills()
      .then((res) => {
        const updatedAssessment = res.data.map((obj) => {
          return {
            skill: obj.skill,
            score: skills.includes(obj.skill) ? 1 : 0,
            category: obj.category,
          };
        });
        setEmployerTechnologiesAssessment(updatedAssessment);
      })
      .catch((err) => {
        console.log(err.message);
        setEmployerTechnologiesAssessment([]);
      });
  }

  function fetchStaffUser() {
    agent.Staff.current()
      .then((res) => {
        setUser({
          ...res.data,
        });
        setDefaultMapCenter({
          lat: Number(res.data.lat),
          lng: Number(res.data.lng),
        });
      })
      .catch((err) => {
        // TODO: show a global, floating Alert?
        console.error(err.message);
      });
  }

  function updateUser(updatedUserValues: {}) {
    if (!!user) {
      setUser({ ...user, ...updatedUserValues });
    }
  }

  //for DevTools to display context name
  UserContext.displayName = 'User Context';

  return (
    <UserContext.Provider
      value={
        isEmployer
          ? {
              defaultMapCenter,
              setDefaultMapCenter,
              user,
              updateUser,
              employerTechnologiesAssessment,
            }
          : { defaultMapCenter, setDefaultMapCenter, user, updateUser }
      }
    >
      <ProfileContext>
        <JobSearchContext>
          <InterviewContext>
            <ConversationsContext>{props.children} </ConversationsContext>
          </InterviewContext>
        </JobSearchContext>
      </ProfileContext>
    </UserContext.Provider>
  );
};

export default UserContextWrapper;
