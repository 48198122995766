import React, { setGlobal, useState, useEffect, useGlobal } from 'reactn';
import { PropsWithChildren } from 'react';
import UserContext from './UserContext';
import PurchaseContext from './PurchaseContext';
import {
  isAuthenticatedToken,
  isEmployerRole,
  isStaffRole,
} from '../utils/authentication';

//initialize global state before components render
declare module 'reactn/default' {
  export interface State extends GlobalStateType {}
}
export interface GlobalStateType {
  isAuthenticated: boolean;
  isStaff: boolean;
  isEmployer: boolean;
}

type UrlParamsType = {
  utm_source: string | undefined;
  utm_medium: string | undefined;
  utm_campaign: string | undefined;
  utm_content: string | undefined;
  discountCode: string | undefined;
  //for navigating to an application detail from an email link from hubspot:
  // format= dentalpost.net/dental-jobs/login/?fromPath=app/post-job/summary/{{custom.job_id}}&u={{custom.resume_encoded_username}}
  username: string | undefined;
  //for contentful page previews
  preview: string | undefined;
};

type LeadCaptureFieldsType = {
  email?: string;
  name?: string;
  phone?: string;
  resource?: string;
  leadSubscribeFlow?: boolean;
};

export type GlobalContextType = {
  urlParams: UrlParamsType;
  updateUrlParams: (params: URLSearchParams) => void;
  resetUrlParams: (params: Partial<UrlParamsType>) => void;
  redirectPath: string | undefined;
  updateRedirectPath: (path: string) => void;
  leadCaptureFields: LeadCaptureFieldsType;
  updateLeadCaptureFields: (props: LeadCaptureFieldsType) => void;
};

export const GlobalContext = React.createContext<GlobalContextType>({
  urlParams: {
    utm_source: undefined,
    utm_medium: undefined,
    utm_campaign: undefined,
    utm_content: undefined,
    discountCode: undefined,
    username: undefined,
    preview: undefined,
  },
  updateUrlParams: (params: URLSearchParams) => {},
  resetUrlParams: (params: Partial<UrlParamsType>) => {},
  redirectPath: undefined,
  updateRedirectPath: (path: string) => {},
  leadCaptureFields: {},
  updateLeadCaptureFields: () => {},
});

const GlobalContextWrapper = (props: PropsWithChildren) => {
  const [isAuthenticated] = useGlobal('isAuthenticated');
  const [urlParams, setUrlParams] = useState<UrlParamsType>({
    utm_source: undefined,
    utm_medium: undefined,
    utm_campaign: undefined,
    utm_content: undefined,
    discountCode: undefined,
    username: undefined,
    preview: undefined,
  });
  const [leadCaptureFields, setLeadCaptureFields] =
    useState<LeadCaptureFieldsType>({});
  const [redirectPath, setRedirectPath] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    setGlobal({
      isAuthenticated: isAuthenticatedToken(),
      isEmployer: isEmployerRole(),
      isStaff: isStaffRole(),
    });
  }, []);

  function updateRedirectPath(path: string) {
    setRedirectPath(path);
  }

  function removeTrailingSlash(param?: string) {
    if (param?.endsWith('/')) {
      return param.slice(0, -1);
    } else return param;
  }

  function updateUrlParams(params: URLSearchParams) {
    //for private route links from emails or wordpress pages/events/blogs the url uses a
    //fromPath search parameter to redirect to after login.
    //i.e. dentalpost.net/login/?fromPath=salary-survey
    const fromPath = params.get('fromPath')?.replace('%2F', '/') || undefined;
    if (!!fromPath) {
      const updatedPath = fromPath.startsWith('/') ? fromPath : `/${fromPath}`;
      setRedirectPath(updatedPath);
    }
    const discountCode =
      params.get('discount-code') || params.get('discountCode') || undefined;
    const utm_source = params.get('utm_source') || undefined;
    const utm_medium = params.get('utm_medium') || undefined;
    const utm_campaign = params.get('utm_campaign') || undefined;
    const utm_content = params.get('utm_content') || undefined;
    const username = params.get('u') || undefined;
    const preview = params.get('preview') || undefined;

    setUrlParams({
      discountCode: removeTrailingSlash(discountCode),
      utm_source: removeTrailingSlash(utm_source),
      utm_medium: removeTrailingSlash(utm_medium),
      utm_campaign: removeTrailingSlash(utm_campaign),
      utm_content: removeTrailingSlash(utm_content),
      username: removeTrailingSlash(username),
      preview: removeTrailingSlash(preview),
    });
  }
  function resetUrlParams(params: Partial<UrlParamsType>) {
    setUrlParams({ ...urlParams, ...params });
  }

  function updateLeadCaptureFields(props: LeadCaptureFieldsType) {
    setLeadCaptureFields({ ...props });
  }

  //for DevTools to display context name
  GlobalContext.displayName = 'Global Context';

  return (
    <GlobalContext.Provider
      value={{
        updateUrlParams,
        redirectPath,
        updateRedirectPath,
        urlParams: urlParams,
        resetUrlParams,
        leadCaptureFields,
        updateLeadCaptureFields,
      }}
    >
      <PurchaseContext>
        {isAuthenticated ? (
          <UserContext>{props.children}</UserContext>
        ) : (
          <>{props.children}</>
        )}
      </PurchaseContext>
    </GlobalContext.Provider>
  );
};

export default GlobalContextWrapper;
