import dayjs from 'dayjs';

import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { JobOwnerSummaryViewType } from '../types/services-api';

const dentistAbbrev = 'DD';
const dentalAssistantAbbrev = 'DA';
const dentalHygienistAbbrev = 'DH';
const frontOfficeAbbrev = 'FO';
const labTechAbbrev = 'LT';
const dentalRepAbbrev = 'SR';
const allPositionsAbbrev = 'ANY';

const dentistName = 'Dentist';
const dentistNameSuffix = '(DDS, DMD)';
const dentalAssistantName = 'Dental Assistant';
const dentalAssistantNameSuffix = '(DA, RDA, EFDA)';
const dentalHygienistName = 'Dental Hygienist';
const dentalHygienistNameSuffix = '(RDH)';
const frontOfficeName = 'Dental Front Office';
const frontOfficeNameSuffix = '';
const labTechName = 'Dental Lab Tech';
const labTechNameSuffix = '';
const dentalRepName = 'Dental Rep';
const dentalRepNameSuffix = '(Sales, etc)';

const dentistDisplayName = `${dentistName} ${dentistNameSuffix}`;
const dentalAssistantDisplayName = `${dentalAssistantName} ${dentalAssistantNameSuffix}`;
const dentalHygienistDislayName = `${dentalHygienistName} ${dentalHygienistNameSuffix}`;
const frontOfficeDisplayName = frontOfficeName;
const labTechDisplayName = labTechName;
const dentalRepDisplayName = `${dentalRepName} ${dentalRepNameSuffix}`;
const allPositionsDisplayName = 'All Positions';

export const convertAbbrevToDisplayName = (symbol) => {
  switch (symbol) {
    case dentistAbbrev:
      return dentistDisplayName;
    case dentalAssistantAbbrev:
      return dentalAssistantDisplayName;
    case dentalHygienistAbbrev:
      return dentalHygienistDislayName;
    case frontOfficeAbbrev:
      return frontOfficeDisplayName;
    case labTechAbbrev:
      return labTechDisplayName;
    case dentalRepAbbrev:
      return dentalRepDisplayName;
    case allPositionsAbbrev:
      return allPositionsDisplayName;
    default:
      return null;
  }
};

export const positionCodeToDisplayName = {
  ANY: 'All Positions',
  ALL: 'All Positions',
  DA: 'Dental Assistant',
  DD: 'Dentist',
  DH: 'Dental Hygienist',
  FO: 'Dental Front Office',
  LT: 'Dental Laboratory',
  SR: 'Dental Sales',
};

export const getPosition = (positionCode) => {
  switch (positionCode) {
    case 'DA':
      return 'Dental Assistant';
    case 'DD':
      return 'Dentist';
    case 'DH':
      return 'Dental Hygienist';
    case 'FO':
      return 'Dental Front Office';
    case 'LT':
      return 'Dental Lab Tech';
    case 'SR':
      return 'Dental Rep';
    case 'ANY':
    case 'ALL':
      return 'All Positions';
    default:
      return '';
  }
};

export const positionCodeToDisplayNamePlural = {
  ANY: 'All Positions',
  ALL: 'All Positions',
  DA: 'Dental Assistants',
  DD: 'Dentists',
  DH: 'Hygienists',
  FO: 'Front Office Staff',
  LT: 'Lab Techs',
  SR: 'Sales Reps',
};

export const positionCodeToSlug = {
  DA: 'dental-assistant',
  DH: 'dental-hygienist',
  FO: 'dental-front-office',
  DD: 'dentist',
  LT: 'dental-lab-tech',
  SR: 'dental-rep',
};

export const jobTypes = {
  PERM: 'Permanent',
  TEMP: 'Temp',
  NATIONAL: 'National',
  PLANNED: 'Temp',
};

export const employmentTypeToDisplayName = {
  PERM: 'Perm',
  TEMP: 'Temp',
};

export const convertAbbrevToDisplayNameParts = (symbol) => {
  switch (symbol) {
    case dentistAbbrev:
      return {
        name: dentistName,
        suffix: dentistNameSuffix,
      };
    case dentalAssistantAbbrev:
      return {
        name: dentalAssistantName,
        suffix: dentalAssistantNameSuffix,
      };
    case dentalHygienistAbbrev:
      return {
        name: dentalHygienistName,
        suffix: dentalHygienistNameSuffix,
      };
    case frontOfficeAbbrev:
      return {
        name: frontOfficeName,
        suffix: frontOfficeNameSuffix,
      };
    case labTechAbbrev:
      return {
        name: labTechName,
        suffix: labTechNameSuffix,
      };
    case dentalRepAbbrev:
      return {
        name: dentalRepName,
        suffix: dentalRepNameSuffix,
      };
    default:
      return null;
  }
};

export const positionAbbrevs = [
  dentistAbbrev,
  dentalAssistantAbbrev,
  dentalHygienistAbbrev,
  frontOfficeAbbrev,
  labTechAbbrev,
  dentalRepAbbrev,
  allPositionsAbbrev,
];

export const positionOptions = [
  {
    code: dentistAbbrev,
    label: convertAbbrevToDisplayName(dentistAbbrev),
  },
  {
    code: dentalAssistantAbbrev,
    label: convertAbbrevToDisplayName(dentalAssistantAbbrev),
  },
  {
    code: dentalHygienistAbbrev,
    label: convertAbbrevToDisplayName(dentalHygienistAbbrev),
  },
  {
    code: frontOfficeAbbrev,
    label: convertAbbrevToDisplayName(frontOfficeAbbrev),
  },
  {
    code: labTechAbbrev,
    label: convertAbbrevToDisplayName(labTechAbbrev),
  },
  {
    code: dentalRepAbbrev,
    label: convertAbbrevToDisplayName(dentalRepAbbrev),
  },
];

export {
  dentistAbbrev,
  dentalAssistantAbbrev,
  dentalHygienistAbbrev,
  frontOfficeAbbrev,
  labTechAbbrev,
  dentalRepAbbrev,
};

export const roleOptions = [
  {
    code: 'staff',
    label: 'Jobseeker',
  },
  {
    code: 'employer',
    label: 'Employer',
  },
  {
    code: 'student',
    label: 'Student',
  },
  {
    code: 'other',
    label: 'Other',
  },
];

export const getBadgeColorByJobStatus = (status) => {
  switch (status) {
    case 'ACTIVE':
      return 'primary';
    case 'UNPAID':
      return 'danger';
    case 'CANCELED':
    case 'CANCELLED':
      return 'light';
    case 'BOOKED':
    case 'FILLED':
      return 'success';

    default:
      return '';
  }
};

export const truncateText = (text = '', lengthLimit = 80) => {
  if (text && text.length > lengthLimit) {
    return text.substring(0, lengthLimit) + '...';
  } else {
    return text;
  }
};

export const displayAddress = (city, state, zip) => `${city}, ${state} ${zip}`;

export const datesWithSlashes = (date) => dayjs(date).format('M/D/YYYY');
export const datesWithDashes = (date) => dayjs(date).format('YYYY-MM-DD');
export const shortDate = (date) => dayjs(date).format('MMM D');
export const time = (date) => dayjs(date).format('h:mm a');

export const isBetween = (value, low, high) => {
  return value >= low && value <= high;
};

export const formatDateSpan = ({
  startMonth,
  startYear,
  endMonth,
  endYear,
}) => {
  const startFormatString = isBetween(startMonth, 1, 12) ? 'MMM YYYY' : 'YYYY';
  const validStartMonth = (isBetween(startMonth, 1, 12) ? startMonth : 1) - 1;
  const startDate = dayjs(new Date(startYear, validStartMonth)).format(
    startFormatString
  );

  let endDate;
  if (
    (endYear === new Date().getFullYear() || endYear === 9999) &&
    endMonth === 9999
  ) {
    endDate = 'Present';
  } else {
    const endFormatString = isBetween(endMonth, 1, 12) ? 'MMM YYYY' : 'YYYY';
    const validEndMonth = (isBetween(endMonth, 1, 12) ? endMonth : 1) - 1;
    endDate = dayjs(new Date(endYear, validEndMonth)).format(endFormatString);
  }

  return `${startDate} - ${endDate}`;
};

export const statusOptions = {
  ACTIVE: 'Active',
  BOOKED: 'Filled',
  CANCELED: 'Canceled',
  DRAFT: 'Draft',
};

export function displayStatus(job: JobOwnerSummaryViewType) {
  if (job.status === 'DRAFT') {
    return 'Draft';
  } else if (job.status === 'BOOKED') {
    return 'Filled';
  } else if (
    job.status === 'CANCELED' ||
    (job.isUnslotted && job.status !== 'BOOKED')
  ) {
    return 'Closed';
  } else if (job.isExpired && job.status === 'ACTIVE') {
    return 'Expired';
  } else if (job.isActive) {
    return 'Active';
  }
}

export const specialtyOptions = {
  GEN: 'General',
  ORTHO: 'Orthodontic',
  PERIO: 'Periodontal',
  ENDO: 'Endodontic',
  PEDO: 'Pediatric',
  PROST: 'Prosthodontic',
  SURG: 'Surgical',
  EXPND: 'Expanded Function',
  COSM: 'Cosmetic',
  MANAG: 'Office Manager',
  RECEP: 'Reception',
  BILL: 'Billing',
  INSUR: 'Insurance',
  TREAT: 'Treatment Coordinator',
  SCHED: 'Scheduling Coordinator',
  STERI: 'Sterilization',
  OTHER: 'Other',
};

export const referralOptions = {
  WORD: 'Word of Mouth (Friend, Colleague)',
  SOCIAL: 'Social Network (Facebook, Twitter, etc.)',
  SEARCH: 'Search Engine (Google, Bing, etc.)',
  DHS: 'Dental Hygiene Seminars',
  JOBBOARD: 'Job Board (Indeed, Monster, etc.)',
  TRADE: 'Magazine/Trade Journal',
  REP: 'Conference/Dental Event/Rep',
  SCHOOL: 'School Participation/Instructor',
  LETTER: 'DentalPost Direct Mail',
  OTHER: 'Other',
};

export const formatCurrency = (unformattedValue) => {
  const amount = unformattedValue ? parseFloat(unformattedValue) : 0;
  if (typeof window !== 'undefined' && typeof Intl !== 'undefined') {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
    return formatter.format(amount);
  } else {
    return `$${amount.toFixed(2)}`;
  }
};

export const formattedAddressString = ({ address, city, state, zip }) => {
  let string = `${city}, ${state}, ${zip}`;
  if (address) {
    string = `${address}, ${string}`;
  }
  return string;
};

export const isEven = (number) => number % 2 === 0;

export const convertStatusToDisplay = ({ status, expired }) => {
  return expired ? 'EXPIRED' : status;
};

export const displayFallback = (str, fallback) => {
  if (_isEmpty(str)) {
    return fallback;
  } else {
    return str;
  }
};

export const displayDOW = (dow) => {
  return displayFallback(dow, 'N/A');
};

export const stateOptions = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const canadianProvinceOptions = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

export const eiScales = [
  {
    id: 'abc1b96e-f442-565b-9e69-ea022b48b176',
    name: 'Self-Awareness',
    iconClass: 'FaHeart',
    strength:
      'The ability to recognize and understand your own feelings in the moment.  This includes being aware of your emotions, what causes specific emotions, and being able to control your emotions when given the opportunity.  Part of understanding your emotions also includes accurately knowing your strengths and limitations and having a desire for self-improvement, learning, and accepting honest feedback.',
    opportunities: [
      {
        title: 'Change how you look at yourself',
        description:
          'Try to view yourself through an objective lens and not through the lens of emotion.  Write down your perceptions of what your top 5 strengths and weaknesses are.  Write down specific, recent examples of situations or behaviors that support why each are strengths or weaknesses.  For each of your weaknesses, write down ways you could have acted or performed differently that could have changed the outcome.  For each of your strengths, write down other situations where this skill can be utilized more often or other ways you can use these behaviors.',
      },
      {
        title: 'Track your progress',
        description:
          'Track your progress on things you are trying to accomplish.  Write down your goals, objectives, or priorities in work or life.  Next write down where you are right now in your progress towards these goals, as well as your desired outcome.  Think of any barriers that could take you off track or that you may need to handle and write down specific ways you will avoid or overcome each of them.  Keep up with tracking your progress as you move along towards these goals and always re-evaluate barriers and how you handle them.  This will allow you to look back on all you have overcome when you reach your goals.',
      },
      {
        title: 'Get feedback from others',
        description:
          'Use formal and informal performance feedback mechanisms that are available to you.  Ask your manager and coworkers for feedback and utilize any formal performance appraisals to get as much information as possible.  If you don’t have formal mechanisms, ask those close to you for candid feedback on specific tasks and behaviors.  When you do this, don’t be defensive when someone tells you about weaknesses you have.  Ask questions on how you could have handled a specific situation better or ask how others would handle the same situation.  Write down the areas for improvement and make a plan on how you will take on cultivating the needed skills to develop yourself.',
      },
    ],
  },
  {
    id: '05aa6e30-cf49-5eae-a875-0f11520ecc94',
    name: 'Self-Management',
    iconClass: 'FaSmile',
    strength:
      'The ability to apply an awareness of your own emotions to direct behavior and decision-making positively.  This includes aspects of controlling negative behaviors that are driven by emotions and being able to remain flexible and adaptable in response to the circumstances of a given situation.',
    opportunities: [
      {
        title: 'Reflect on and manage negative emotions',
        description:
          'Whenever you feel a negative emotion come on, take deep breaths and don’t assume anything or jump to conclusions.  Before you reach a judgment, consider the situation from multiple perspectives and try to remain as objective as possible in your evaluation of the situation.  It is often useful to write down each perspective considered and reflect on what drives each person and emotion in a given situation.  We often get caught up in the negative emotions we feel and that leads to assumptions being made, and ultimately, we jump to conclusions.  When you are faced with a negative emotion, don’t get overwhelmed, take a step back, and reflect on what about the situation is making you feel angry/upset/anxious.  Write down and track things that cause negative emotions and see if there are trends over time.  The more we are aware of and understand our emotional triggers, the more we catch ourselves before we let those emotions consume us and our judgment.',
      },
      {
        title: 'Tackle adversity with positivity and optimism',
        description:
          'When we are stressed out or facing tough situations, we have a tendency to be negative in our thoughts and reactions.  We all will struggle sometimes but reacting positively to these struggles can set you up for success.  Instead of complaining or reacting with a negative response, practice using optimism and positivity.  If you made an error or got in trouble for something that you missed, don’t immediately react with blame, anger, or deflection.  Think about what you did wrong, admit your mistake, and pivot to thinking of the mistake as an opportunity for development.  Always look at a situation from the mindset of “what can I learn from this?” Think back over the last 2-3 times you faced adversity and break each situation down into what you learned or could have learned (if you had taken a positive mindset).',
      },
      {
        title: 'Being flexible and adaptable in the face of change',
        description:
          'Most people do not like change or uncertainty, but it is a reality we all have learn to handle.  Whether it is a major change in your life, like a divorce, or a less major change, like a patient needing to be fit in an already busy schedule, you have to be ready to handle it without immediately reacting with negative emotions or behavior.  When you are faced with change or uncertainty, first ask yourself if there is anything you can do to alter the situation, either by changing the likelihood of something or altering the outcome.  If the answer is ‘no,’ then work on remaining calm and moving on to dealing with the situation at hand by focusing on what you will do rather than the “what ifs” of the situation.  The key is that you learn to focus on action in what you can control and not dwell on things out of your control.  Consider the last 3 ‘changes’ you encountered at work that made you upset.  How did you react?  Did you immediately take a reaction based on emotion or did you focus on practical action around what you could impact?  Now that you are removed from those situations, thinking about what the best action you could have taken and removing the emotions should be easier.  The more often you do this, the easier it will be to avoid an emotional reaction in the moment and the easier it will be to adapt to whatever situation you are faced with.',
      },
      {
        title: 'Hit the ‘pause’ button',
        description:
          'How many times have you hit ‘send’ on a text or an email and immediately regretted that decision?  We all sometimes allow emotions to drive our behaviors, but this can lead to us saying something hurtful or offensive to others when we didn’t really mean it.  The goal should be to get in the habit of recognizing when you’re emotional and put some space between that emotion and your response to the situation.  Practice always reading a message/email multiple times before sending it.  Walk away when someone says something upsetting that you may want to respond to immediately.  You can also have a trusted friend or colleague review messages to be a second set of eyes for the appropriateness of your message.  The more you can practice hitting the “pause” button before using a knee-jerk response to someone, the more you will be able to manage yourself and your interactions with others.  This will ultimately lead to far less regret over emails or messages you would have sent.',
      },
    ],
  },
  {
    id: '987c7702-f72a-548a-8099-da3dcbae6e00',
    name: 'Social Awareness',
    iconClass: 'FaComments',
    strength:
      'The ability to correctly perceive and understand the emotions and perspectives of others and respond to their needs.  This includes the sensing or anticipating of others’ feelings and needs, showing empathy, and utilizing an understanding of their needs to provide the help they require.',
    opportunities: [
      {
        title: 'Practice empathy',
        description:
          'To be able to recognize emotion in others, you have to be able to understand emotions outside of your own.  The best way to do this is to try to understand the perspectives of others who don’t see things the same way as you.  Spend time talking to those who you disagree with or that may look at an issue with a different perspective.  The more you talk to others about how they view a situation or issue, the better you will understand them and the feelings they have.  Spend time asking “why” in these conversations and you will gain insight into how someone else may view a situation very differently from you.  Maybe they are seeing or focusing on different aspects of the information presented than you are and that leads them to different conclusions.  You may not agree with them or see things the same way, but through hearing someone else’s viewpoint, you are at least able to understand where they are coming from.',
      },
      {
        title: 'Try putting yourself in someone else’s shoes',
        description:
          'While this phrase is frequently used, it is often easier said than done, especially when emotions are involved.  The more you are able to see something from someone else’s perspective, the more you will be able to recognize how they are feeling and adapt your behavior to their needs.  To help build perspective, try people watching.  Choose a target and ask yourself, “how would I feel if I were them right now?”  You don’t actually have to sit and people watch to do this exercise but using that as a place to start can be effective.  For example, you may not be afraid of going to the doctor yourself, but those who aren’t used to that environment, it can be terrifying.  You have experience and knowledge of that environment that informs your reaction and how you feel.  Try removing that experience and your prior conceptions of the environment or situation.  Then put yourself in someone’s shoes who is experiencing that environment for the first time.  Try writing down all the things that you may notice that would elicit different emotions (fear, happiness, anxiety, etc.).  You will quickly see things that you may not have considered when you base your judgement on only your experiences.',
      },
      {
        title: 'Actively listen',
        description:
          'We often find ourselves multi-tasking when we are “listening” to other people talk to us (checking your phone, watching TV, looking out the window, etc.).  When we aren’t fully listening to someone, we won’t pick up on the verbal and non-verbal cues they may be giving us that would provide us insight into how they are actually feeling.  In order to understand and recognize the emotions of others, we need to be listening actively and on the look out for those types of cues.  Even when we think we are listening, we often are just passively hearing them and spending our time preparing our response rather than truly listening to the points they are making.  When you stop and take time to listen to someone you are speaking with, you can listen to every word and spend that time trying to understand the emotions and feelings they are talking about rather than preparing your next retort.  Practice putting down your phone and actively engaging with those you are talking to.  Don’t worry about that witty response and focus on what they are saying and doing that may be providing you with insight into their feelings.  Ask questions when you don’t understand something and ask them why they feel a certain way.  The more you actively listen to everything they say (including non-verbal cues), the better you will be at recognizing and understanding emotions in others.',
      },
    ],
  },
  {
    id: 'cdfd553c-e394-5f00-b2a2-b1ad272d0a32',
    name: 'Relationship Management',
    iconClass: 'FaUsers',
    strength:
      'The ability to apply awareness of the emotions being experienced by yourself and others in order to appropriately manage social interactions or situations.',
    opportunities: [
      {
        title: 'The dreaded networking',
        description:
          'Part of Relationship Management is being sociable and the best way to build your social skills is to get out and be social.  Interacting with those we don’t know can be terrifying, but the more often you do it, the more you will learn about yourself and others.  Find events where you will have common interests with those in attendance and force yourself to go to them.  This provides you with easy ice-breakers to start conversations about and often, it is the starting conversations aspect that is the most difficult to do when you’re not naturally outgoing.  When you are at these events, make sure you are using the other skills you have worked on, like active listening and trying to understand others’ perspectives, because they are the foundation of being able to manage relationships.  After all, you can’t get to know someone if you’re checking your phone every minute.  Before you know it, you will be building your network and may actually enjoy it.  Remember, you’re not the only person at those events who may be terrified of social interactions!',
      },
      {
        title: 'See conflict as an opportunity for growth',
        description:
          'As a society, we often end up in a bubble of friends that all agree with us and tend to ‘block’ out those we disagree with.  Think about the last time you saw someone post something to social media that you strongly disagreed with.  How often do you engage in meaningful conversation in these situations and how often do you ignore them or hide their posts/messages?  We often forget that we have to work with and interact with people we disagree with every day, and when you’re in the workplace, hitting ‘block’ isn’t an option.  No two people can have the same opinions, desires, viewpoints, and expectations in every situation.  Conflict is unavoidable, so we need to learn how to deal with it if we want to better manage relationships.  Rather than walking away from your next disagreement, spend time engaging with the person and actively listening to their side (remember, don’t spend that time coming up with a good one liner retort but actually listen to their perspective).  Don’t approach the conversation with the goal of winning the argument, rather use it as an opportunity for understanding their perspective and building mutual respect.  When we understand why someone looks at something differently than we do, we are able to resolve conflict in more productive and healthy ways.  When you are able to see conflict as an opportunity to build a stronger relationship, you won’t find the need to block these people from our lives',
      },
      {
        title: 'Come from a personal perspective',
        description:
          'We often make sweeping statements about things when they really are just our own opinions about them.  For instance, if I say, “This show is the worst!”, it doesn’t give you the chance to provide your thoughts.  It effectively tells you that my opinion is the only opinion.  It is a subtle thing, but when we have conversations like this, we cut others off from having an opinion or force them into a conflict.  If I instead said, “I think this show is the worst.”, I am providing my opinion but not negating your opinion at the same time.  This allows for a discussion rather than a conflict.  Most social interactions can be positively impacted simply by keeping things in the first-person.  When you keep your statements in the first-person, you are allowing the other person to feel like an active participant in the discussion rather than just a listener.  It will open up for a broader discussion about the topic and doesn’t immediately put the other person on the defensive.  Practice expressing your opinions in the first-person and allowing room for others to have an opinion too.',
      },
    ],
  },
];

export const formatMsa = (msa) => {
  return msa.substr(0, msa.length - 3) + ', ' + msa.substr(-2);
};

export const dpRed = '#c7276b';
export const dpOrange = '#ff6700';
export const dpPurple = '#7f60a8';
export const dpGreen = '#83bf23';
export const dpTeal = '#00bbb9';
export const dpGray = '#d1d2d4';
export const dpBlue = '#244289';
export const dpBlue10 = `#e5f8f8`;
export const dpGreen10 = `#f3f8e9`;
export const dpLight = `#d1d2d4`;

export const interviewTypeOptions = [
  {
    value: 'INPERSON',
    label: 'In-Person Meeting/Interview',
  },
  { value: 'VIRTUAL', label: 'Virtual Meeting/Interview' },
  { value: 'PHONE', label: 'Phone Conversation/Interview' },
  { value: 'WORKING', label: 'Working Interview' },
  { value: 'TEAM', label: 'Team Interview' },
];

export const interviewLabels = {
  INPERSON: 'In-Person',
  VIRTUAL: 'Virtual',
  Phone: 'Phone',
  Working: 'Working',
  Team: 'Team',
};

export function groupBy(arr, criteria) {
  const newObj = arr.reduce(function (acc, currentValue) {
    if (!acc[currentValue[criteria]]) {
      acc[currentValue[criteria]] = [];
    }
    acc[currentValue[criteria]].push(currentValue);
    return acc;
  }, {});
  return newObj;
}

export const excludedSpecialties = ['GEN', 'General', 'OTHER', 'Other'];

export const jobPostDropdownOption = (
  job: JobOwnerSummaryViewType,
  jobSlotList
) => {
  return (
    <option
      key={`${
        job.status === 'DRAFT'
          ? 'draft-'
          : !job.isSubscription && job.isExpired
            ? 'expired-'
            : job.isUnslotted
              ? 'closed- '
              : ''
      }job-${job.id}`}
      value={job.id}
    >
      {`#${job.id} - `}
      {job.isBasic ? 'Basic' : 'Premium'}
      {' Job '}
      {job.isNational && ' + National Boost'}
      {job.isSubscription &&
        !job.isUnslotted &&
        !job.isExpired &&
        job.status !== 'DRAFT' &&
        `Subscription`}
      {job.inviteCredits > 0 && ` + ${job.inviteCredits} Invites to Apply`}
      {!!job.postedDate
        ? ` - Posted ${dayjs(job.postedDate).format('MMM DD, YYYY')}`
        : ` - ${job.isSubscription ? 'Created' : 'Purchased'} ${dayjs(
            job.createdDate
          ).format('MMM DD, YYYY')} `}
      {`${
        !!job.position
          ? ` - ${positionCodeToDisplayName[job.position]} - ${job.zip} `
          : ' - Unassigned '
      }`}
      {job.status !== 'DRAFT' && `- ${job.applys} Apps`}

      {((!job.isSubscription && job.isExpired) ||
        job.isUnslotted ||
        job.status === 'CANCELED' ||
        job.status === 'FILLED') &&
        ` - closed ${dayjs(job.isSubscription || job.status !== 'ACTIVE' ? job.updatedDate : job.expiredDate).format('MMMM DD, YYYY')}`}
      {!job.isSubscription &&
        !job.isExpired &&
        job.status !== 'DRAFT' &&
        ` - expires ${dayjs(job.postedDate)
          .add(job.days, 'day')
          .format('MMMM DD, YYYY')}`}
      {jobSlotList.find(
        (slot) => slot.activeJobId === job.id && !!slot.canceledDate
      ) && ` - ends: ${dayjs(job.expiredDate).format('MMM D, YYYY')}`}
    </option>
  );
};

export function formatLocaleStringCompact(numberAsString: string) {
  return Number(numberAsString).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'short',
  });
}

export function formatSalary(salary: string, salaryUnits: string) {
  if (!!salary && !!salaryUnits) {
    const isDollarAmt =
      salaryUnits.startsWith('HOUR') || salaryUnits.startsWith('YEAR');
    const isRange = salaryUnits.endsWith('RANGE');

    const salaryUnitDisplayLabel = () => {
      if (salaryUnits.startsWith('HOUR')) {
        return ' per Hour';
      } else if (salaryUnits.startsWith('YEAR')) {
        return ' per Year';
      } else if (salaryUnits.startsWith('PRODUCTION')) {
        return '% of Production';
      } else if (salaryUnits.startsWith('COLLECTION')) {
        return '% of Collections';
      } else {
        return '';
      }
    };
    return `${isDollarAmt ? '$' : ''}${
      isDollarAmt
        ? isRange
          ? `${formatLocaleStringCompact(salary.split(' ')[0])} to $${formatLocaleStringCompact(salary.split(' ')[2])}`
          : formatLocaleStringCompact(salary)
        : salaryUnits.endsWith('RANGE')
          ? `${Number(salary.split(' ')[0])}% to ${Number(salary.split(' ')[2])}`
          : Number(salary)
    }${salaryUnitDisplayLabel()}`;
  } else {
    return salary;
  }
}
