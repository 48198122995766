import React, { useState, useContext, PropsWithChildren, useRef } from 'react';
import classnames from 'classnames';
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';

import Sidenav from './Sidenav';
import Navbar from './Navbar';
import AuthenticatedDropdownMenu from './AuthenticatedDropdownMenu';
import { FaBars } from 'react-icons/fa';
import {
  UserContext,
  UserContextType,
  isEmployerType,
  isStaffType,
} from '../../context/UserContext';
import { NavCategoriesType } from './Layout';
import Footer from './Footer';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const AuthenticatedLayout = (
  props: PropsWithChildren<{ navCategories: NavCategoriesType }>
) => {
  const windowDims = useWindowDimensions();
  const [bannerHeight, setBannerHeight] = useState(0);
  const { user } = useContext(UserContext) as UserContextType;

  const [offcanvasIsOpen, setOffcanvasIsOpen] = useState(false);
  function toggleOffcanvas() {
    setOffcanvasIsOpen(!offcanvasIsOpen);
  }
  function updateLayout(height: number) {
    setBannerHeight(height);
  }

  return (
    <>
      <div
        className={classnames('w-100 border-bottom bg-white', {
          'border-success': isStaffType(user),
          'border-secondary': isEmployerType(user),
        })}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000,
        }}
      >
        <Navbar
          navCategories={props.navCategories}
          updateLayout={updateLayout}
          bannerHeight={bannerHeight}
        >
          <AuthenticatedDropdownMenu />
          {/* MOBILE DROPDOWN ACTIONS*/}
          <Button
            color="link"
            onClick={toggleOffcanvas}
            className={`d-xl-none text-black pe-0`}
            aria-label="Main Menu"
          >
            <FaBars className="dp-font-lg" />
          </Button>
          <Offcanvas
            direction="end"
            isOpen={offcanvasIsOpen}
            toggle={toggleOffcanvas}
          >
            <OffcanvasHeader toggle={toggleOffcanvas}></OffcanvasHeader>
            <OffcanvasBody className="bg-solids-off-white-50 px-0 pt-0">
              <Sidenav toggle={toggleOffcanvas} />
            </OffcanvasBody>
          </Offcanvas>
        </Navbar>
      </div>
      <div className="bg-solids-off-white-50 authenticated w-100 position-relative">
        <div
          className="d-none d-xl-flex d-print-none bg-white border-end justify-content-center align-self-stretch"
          style={{
            width: '225px',
            height: `calc(100dvh - 85px${bannerHeight > 0 ? ` - ${bannerHeight}px)` : ')'}`,
            overflowY: 'scroll',
            position: 'fixed',
            top: `${bannerHeight > 0 ? `${85 + bannerHeight}px` : '85px'}`,
            left: 0,
            transition: 'height 1s linear',
          }}
        >
          <Sidenav />
        </div>
        <div
          className="col"
          id="main-content"
          style={{
            position: 'fixed',
            top: `${bannerHeight > 0 ? `${85 + bannerHeight}px` : '85px'}`,
            left: !!windowDims && windowDims?.width > 1200 ? '225px' : 0,
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: `calc(100dvh - 85px${bannerHeight > 0 ? ` - ${bannerHeight}px)` : ')'}`,
            width:
              !!windowDims && windowDims?.width > 1200
                ? 'calc(100dvw - 225px)'
                : '100dvw',
            transition: 'height 1s linear',
          }}
        >
          <div
            style={{
              minHeight: '100%',
            }}
          >
            {props.children}
          </div>
          <Footer displayLeadForm={false} />
        </div>
      </div>
    </>
  );
};

export default AuthenticatedLayout;
